import axios from 'axios';

const instance = axios.create({
     baseURL:"/API/"
      //   baseURL:"https://alraihaan.mazaraat.org/API/"
      
   // baseURL:'https://localhost:44338/'
});

export default instance;
