import React from "react";
//import { ProgressSpinner } from "primereact/progressspinner";
import "./Spinner.css";

const spinner = () => (
  <div className="loaderOverlay d-flex justify-content-center align-items-center">
    {/* <ProgressSpinner strokeWidth={4}/> */}
    {/* <div className="Loader h-100">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div> */}
     <div className="loader"></div> 
  </div>
);

export default spinner;
