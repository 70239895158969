import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer>
        <Container fluid="lg">
          <Row className="flex-row-reverse">
            <Col xs={12} md={7}>
              <Row>
                <Col xs={6} md={5}>
                  <h2>Quick links</h2>
                  <Nav className="flex-column">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#room">Rooms</Nav.Link>
                    {/* <Nav.Link href="#explore">Explore</Nav.Link> */}
                    <Nav.Link href="#contact">Contact</Nav.Link>
                  </Nav>
                </Col>
                <Col xs={6} md={5}>
                  <h2>Company</h2>
                  <Nav className="flex-column">
                    <Nav.Link href="#">Privacy policy</Nav.Link>
                    <Nav.Link href="#">Refund policy</Nav.Link>
                    <Nav.Link href="#receipt">F.A.Q</Nav.Link>
                    <Nav.Link href="#about">About</Nav.Link>
                  </Nav>
                </Col>
              </Row>
            </Col>
            <Col md={5}>
              <Navbar.Brand href="#home"></Navbar.Brand>
              {/* <p>
                (Text to be provided)
              </p> */}
            </Col>

            {/* <Col md={2}><h2>Social media</h2>
              <Nav className="flex-column">
                <Nav.Link href="#">Facebook</Nav.Link>
                <Nav.Link href="#">Twitter</Nav.Link>
                <Nav.Link href="#">Instagram</Nav.Link>
                <Nav.Link href="#">Linkedin</Nav.Link>
              </Nav></Col>
            <Col md={3}><h2>Newsletter</h2>
              <p>Kindly subscribe to our newsletter to get latest deals on our rooms and vacation discount.</p>
              <div className="subscribeSection">
                <Form.Control
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  aria-describedby="basic-addon2"
                />
                <Button variant="primary" id="button-addon2">
                  Subscribe
                </Button>
              </div>
            </Col> */}
          </Row>
        </Container>
        <div className="footnote d-flex justify-content-center">
          <span>Al Raihaan 2024</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
